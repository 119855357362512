import React, { useRef, useState } from "react"
import useOutsideClick from "../../../hooks/useOutsideClick"

const Dropdown = ({ title }) => {
  const [show, setShow] = useState(false)
  const ref = useRef()

  useOutsideClick(ref, () => {
    setShow(false)
  })

  return (
    <div
      className="menu-margin explore-functional-button sort-button sort-filter-relative"
      onClick={() => setShow(true)}
      ref={ref}
    >
      <div className="filter-text">{title}</div>
      <div className="filter-icon">
        <img src="/img/explore/sort.svg" />
      </div>

      {show && (
        <div className="sort-filter-container">
          <div className="sort-filter-item">MOST POPULAR</div>
          <div className="sort-filter-item">A-Z</div>
          <div className="sort-filter-item">LAST ADDED</div>
          <div className="sort-filter-item">PRICE LOW TO HIGH</div>
        </div>
      )}
    </div>
  )
}

export default Dropdown
