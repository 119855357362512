import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import OtherAvailableOptions from "../../../components/ContentBuilder/SwiperList/OtherAvailableOptions"

const QuickViewModal = ({ showModal }) => {
  const [show, setShow] = useState(showModal);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isReadmore, setReadmore] = useState(true);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size={'xl'}
      animation={true}
      className="itemModal"
    >
      <div className="row mb-4">
        <div className="col-12 col-lg-6">
          <div className="btn-close mb-3 d-block d-lg-none" onClick={handleClose}></div>
          <div className="itemModal-wrap">
            <img src="/img/bg-big.jpg"></img>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="btn-close d-none d-lg-block" onClick={handleClose}></div>
          <h3 className="mb-1 border-bottom-4px">Brass Gold Tap</h3>
          <div className="list-group list-group-flush mb-4">
            <div className="list-group-item px-0">
              <p>The tap is designed to go hand-in-hand with a coloured sink from Meir’s Kitchen range, but it will ultimately serve as a welcome addition to any space. A minimalist pin lever allows for effortless control.</p>
              {!isReadmore && 
                <>
                  <p>The 140-degree swivel spout and high clearance outlet will come in handy when washing larger items. The pull-out spray head gives you complete coverage over everything in your sink, and will prove useful when cleaning grimy sink bowls. The spray head is sheathed in metal for greater durability. 
                  </p>
                  <p>The sleek geometric lines of this Meir tap will add contemporary splendour to any kitchen.</p>
                </>
              }
              <div className="text-right">
                <button type="button"
                  className="button-link"
                  onClick={() => setReadmore(!isReadmore)}
                >
                  {isReadmore ? "Read more" : "Read less"}
                </button>
              </div>
            </div>
            <div className="list-group-item px-0">
              <div className="row align-items-center">
                <div className="col">
                  <h5 className="mb-0">COST OVER STANDARD</h5>
                </div>
                <div className="col-auto">
                  <p className="font-weight-medium">$195.00</p>
                </div>
              </div>
            </div>
            <div className="text-right mt-4">
              <a href="#" className="btn btn-black btn-lg btn-w210">VIEW PRODUCT</a>
            </div>
          </div>
        </div>
      </div>
      <OtherAvailableOptions />
    </Modal>
  )
}

export default QuickViewModal
