import React from "react"
import SwiperCore, { Navigation } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

SwiperCore.use([Navigation]);

const OtherAvailableOptions = () => {
  const params = {
    spaceBetween: 10,
    navigation: {
      prevEl: '.swiper-button-prev-unique',
      nextEl: '.swiper-button-next-unique',
    },
    breakpoints: {
      1400: {
        slidesPerView: 5,
      },
      1200: {
        slidesPerView: 4,
      },
      992: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 2,
      },
      0: {
        slidesPerView: 1,
      },
    },
  }
  return (
    <div className="other-available-wrap">
      <h3 className="main-title">Other options: </h3>
      <div className="other-available">
        <Swiper navigation {...params}>
          <SwiperSlide>
            <a href="#" className="product-items">
              <div className="product-items-thumb">
                <img src="img/products/item-1.png"></img>
              </div>
              <p className="product-items-desc">Black | $120.00</p>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="#" className="product-items">
              <div className="product-items-thumb">
                <img src="img/products/item-2.png"></img>
              </div>
              <p className="product-items-desc">Black | $120.00</p>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="#" className="product-items">
              <div className="product-items-thumb">
                <img src="img/products/item-3.png"></img>
              </div>
              <p className="product-items-desc">Black | $120.00</p>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="#" className="product-items">
              <div className="product-items-thumb">
                <img src="img/products/item-4.png"></img>
              </div>
              <p className="product-items-desc">Black | $120.00</p>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="#" className="product-items">
              <div className="product-items-thumb">
                <img src="img/products/item-5.png"></img>
              </div>
              <p className="product-items-desc">Black | $120.00</p>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="#" className="product-items">
              <div className="product-items-thumb">
                <img src="img/products/item-6.png"></img>
              </div>
              <p className="product-items-desc">Black | $120.00</p>
            </a>
          </SwiperSlide>
        </Swiper>
        
        <div className="swiper-button-prev-unique"></div>
        <div className="swiper-button-next-unique"></div>
      </div>
    </div>
  )
}

export default OtherAvailableOptions
