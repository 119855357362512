import React, { useState, useEffect } from "react"
import SlideToggle from "react-slide-toggle"
import InputRange from "react-input-range"
import "react-input-range/lib/css/index.css"

const FilterModal = ({ setSelections, removeItem }) => {
  const [byRoom, setByRoom] = useState({
    kitchen: false,
    bedroom: false,
    bathroom: false,
    living: false,
  })
  const [style, setStyle] = useState({
    contemporary: false,
    modern: false,
    coastal: false,
    hamptons: false,
    classical: false,
  })
  const [colour, setColour] = useState({
    black: false,
    gold: false,
    white: false,
    brass: false,
    silver: false,
  })
  const [houseStyle, setHouseStyle] = useState({
    "single storey": false,
    "double storey": false,
    "split level": false,
    duplex: false,
  })

  const onChangeByRoom = e => {
    const { checked, name } = e.target
    byRoom[name] = checked
    setByRoom(byRoom)
    handleFilter()
  }

  const onChangeStyle = e => {
    const { checked, name } = e.target
    style[name] = checked
    setStyle(style)
    const filter = { ...byRoom, ...style, ...colour, ...houseStyle }
    handleFilter()
  }

  const onChangeColour = e => {
    const { checked, name } = e.target
    colour[name] = checked
    setColour(colour)
    handleFilter()
  }

  const onChangeHouseStyle = e => {
    const { checked, name } = e.target
    houseStyle[name] = checked
    setHouseStyle(houseStyle)
    handleFilter()
  }

  const handleFilter = () => {
    const filter = { ...byRoom, ...style, ...colour, ...houseStyle }

    const filterSelected = Object.keys(filter).filter(function (key, index) {
      return filter[key] === true
    })
    setSelections(filterSelected)
  }

  useEffect(() => {
    if (byRoom[removeItem]) {
      byRoom[removeItem] = false
      setByRoom(byRoom)
      const element = document.getElementById(removeItem)
      element.checked = false
      handleFilter()
    }
    if (style[removeItem]) {
      style[removeItem] = false
      setStyle(style)
      const element = document.getElementById(removeItem)
      element.checked = false
      handleFilter()
    }
    if (colour[removeItem]) {
      colour[removeItem] = false
      setColour(colour)
      const element = document.getElementById(removeItem)
      element.checked = false
      handleFilter()
    }
    if (houseStyle[removeItem]) {
      houseStyle[removeItem] = false
      setHouseStyle(houseStyle)
      const element = document.getElementById(removeItem)
      element.checked = false
      handleFilter()
    }
  }, [removeItem])

  return (
    <div className="m-2">
      <div className="filter-modal-container">
        <div className="d-flex justify-content-between">
          <div className="tille-filter-modal cursor-pointer">RESET FILTERS</div>
        </div>

        <SlideToggle>
          {({ toggle, setCollapsibleElement }) => (
            <div className="widget widget-collapsible">
              <h3 className="widget-title">
                <div
                  className="wiget-title-name"
                  onClick={e => {
                    toggle(e)
                    e.preventDefault()
                  }}
                >
                  BY ROOM
                </div>
              </h3>
              <div className="widget-body" ref={setCollapsibleElement}>
                {Object.keys(byRoom).map(function (key, index) {
                  return (
                    <div className="d-flex align-items-center justify-content-between mt-2 mb-2">
                      <div className="name-checkbox">{key}</div>
                      <input
                        type="checkbox"
                        name={key}
                        id={key}
                        onChange={onChangeByRoom}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </SlideToggle>
        <SlideToggle>
          {({ toggle, setCollapsibleElement }) => (
            <div className="widget widget-collapsible">
              <h3 className="widget-title">
                <div
                  className="wiget-title-name"
                  onClick={e => {
                    toggle(e)
                    e.preventDefault()
                  }}
                >
                  STYLE
                </div>
              </h3>
              <div className="widget-body" ref={setCollapsibleElement}>
                {Object.keys(style).map(function (key, index) {
                  return (
                    <div className="d-flex align-items-center justify-content-between mt-2 mb-2">
                      <div className="name-checkbox">{key}</div>
                      <input
                        type="checkbox"
                        name={key}
                        id={key}
                        onChange={onChangeStyle}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </SlideToggle>

        <SlideToggle>
          {({ toggle, setCollapsibleElement }) => (
            <div className="widget widget-collapsible">
              <h3 className="widget-title">
                <div
                  className="wiget-title-name"
                  onClick={e => {
                    toggle(e)
                    e.preventDefault()
                  }}
                >
                  BY COLOUR
                </div>
              </h3>
              <div className="widget-body" ref={setCollapsibleElement}>
                {Object.keys(colour).map(function (key, index) {
                  return (
                    <div className="d-flex align-items-center justify-content-between mt-2 mb-2">
                      <div className="name-checkbox">{key}</div>
                      <input
                        type="checkbox"
                        name={key}
                        id={key}
                        onChange={onChangeColour}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </SlideToggle>
        <SlideToggle>
          {({ toggle, setCollapsibleElement }) => (
            <div className="widget widget-collapsible">
              <h3 className="widget-title">
                <div
                  className="wiget-title-name"
                  onClick={e => {
                    toggle(e)
                    e.preventDefault()
                  }}
                >
                  HOUSE TYPE
                </div>
              </h3>
              <div className="widget-body" ref={setCollapsibleElement}>
                {Object.keys(houseStyle).map(function (key, index) {
                  return (
                    <div className="d-flex align-items-center justify-content-between mt-2 mb-2">
                      <div className="name-checkbox">{key}</div>
                      <input
                        type="checkbox"
                        name={key}
                        id={key}
                        onChange={onChangeHouseStyle}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </SlideToggle>
        <SlideToggle>
          {({ toggle, setCollapsibleElement }) => (
            <div className="widget widget-collapsible">
              <h3 className="widget-title">
                <div
                  className="wiget-title-name"
                  onClick={e => {
                    toggle(e)
                    e.preventDefault()
                  }}
                >
                  PRICE
                </div>
              </h3>
              <div className="widget-body" ref={setCollapsibleElement}>
                <div className="input-range-container">
                  <InputRange
                    formatLabel={value => `$${value}`}
                    maxValue={1000}
                    minValue={0}
                    step={100}
                    onChange={value => {}}
                  />
                </div>
              </div>
            </div>
          )}
        </SlideToggle>
        <SlideToggle>
          {({ toggle, setCollapsibleElement }) => (
            <div className="widget widget-collapsible">
              <h3 className="widget-title">
                <div
                  className="wiget-title-name"
                  onClick={e => {
                    toggle(e)
                    e.preventDefault()
                  }}
                >
                  BY DESIGNER
                </div>
              </h3>
              <div className="widget-body" ref={setCollapsibleElement}>
                <div className="d-flex align-items-center justify-content-between mt-2 mb-2">
                  <div>Tiffany </div>
                  <input type="checkbox" />
                </div>
              </div>
            </div>
          )}
        </SlideToggle>
      </div>
    </div>
  )
}

export default FilterModal
