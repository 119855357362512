import React, { useState } from "react"

import Layout from "../templates/Page"
import { Container,Tabs,Tab } from 'react-bootstrap';
import FilterModal from "../components/ContentBuilder/Modal/FilterModal"
import QuickViewModal from "../components/ContentBuilder/Modal/QuickViewModal"
import Dropdown from "../components/ContentBuilder/Dropdown"
import Subscribe from "../components/Footer/subscribe";

const MyBoardPage = () => {
  const [showModal, setShowModal] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [currentTab, setCurrentTab] = useState(0)

  const tabs = ["My Quotes 1", "My Quotes 2", "My Quotes 3", "My Quotes 4"]

  return (
    <Layout>
      <Container>
        <Tabs defaultActiveKey="home" id="noanim-tab-example">
          <Tab eventKey="home" title="Home">
            <div className="board-profile">
              <div className="board-circle">
                <div className="board-name">JS</div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="profile" title="Profile">
            <div className="board-profile">
              <div className="board-circle">
                <div className="board-name">CSS</div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="contact" title="Contact" disabled>
            <div className="board-profile">
              <div className="board-circle">
                <div className="board-name">HTML</div>
              </div>
            </div>
          </Tab>
        </Tabs>
      </Container>
      <div className="container">
        <div className="d-none d-sm-flex justify-content-center align-items-center wrap-section mt-50 mb-50">
          <div className="board-fix-position">
            <div className="board-profile">
              <div className="board-circle">
                <div className="board-name">JS</div>
              </div>
            </div>
            <div className="board-icon">
              <div className="my-board-icon">
                <img src="/img/drak-plus.svg" />
              </div>
            </div>
          </div>
        </div>

        <div className="wrap-section mt-30 mb-30 row border-bottom-cell-np justify-content-between">
          {tabs.map((item, idx) => (
            <div
              className={
                currentTab === idx
                  ? "my-board-name cursor-pointer active"
                  : "my-board-name cursor-pointer"
              }
              key={idx}
              onClick={() => setCurrentTab(idx)}
            >
              <div>{item}</div>
            </div>
          ))}
        </div>
        <div className="d-none d-sm-flex justify-content-center explore-functional my-board">
          <div className="menu-margin search-box">
            <input className="filter-text" placeholder="SEARCH" />
            <div className="filter-icon">
              <img src="/img/explore/search.svg" />
            </div>
          </div>
        </div>
        <div className="explore-functional d-sm-flex justify-content-center my-board">
          <div
            className="menu-margin explore-functional-button filter-button"
            onClick={() => setShowFilter(true)}
          >
            <div className="filter-text">FILTER</div>
            <div className="filter-icon">
              <img src="/img/explore/filter.svg" />
            </div>
          </div>
          <Dropdown title="SORT" />
          <div className="menu-margin explore-functional-button filter-button dark-button">
            <div className="filter-text">ORGANISE</div>
          </div>
          <div>
            <img src="/img/openLink.svg" />
          </div>
        </div>

        <Subscribe customClass="mt-30"></Subscribe>
      </div>
      <QuickViewModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
      />
      <FilterModal
        isOpen={showFilter}
        closeModal={() => setShowFilter(false)}
      />
    </Layout>
  )
}

export default MyBoardPage
